import useFloating from '@/fuse/javascript/composables/use_floating';
import ApplicationController from 'modules/application_controller';
import getOffset from 'plugins/element/get_offset';
import debounce from 'plugins/utilities/debounce';
import { releaseFocus, trapFocus } from 'plugins/utilities/focus_trap';

export default class extends ApplicationController {
  static get targets() {
    return ['trigger', 'bodyContainer', 'body'];
  }

  static get values() {
    return {
      skipHeaderPadding: {
        type: Boolean,
        default: false,
      },
      openOnLoad: {
        type: Boolean,
        default: false,
      },
      offset: {
        type: Number,
        default: 0,
      },
      placement: {
        type: String,
        default: 'bottom-start',
      },
      appendTo: {
        type: String,
        default: 'auto',
        // possibleValues: ['auto', 'parent'],
      },
      overlay: {
        type: Boolean,
        default: false,
      },
      useShift: {
        type: Boolean,
        default: false,
      },
      isNestedMenu: {
        type: Boolean,
        default: false,
      },
    };
  }

  initialize() {
    this.floating = useFloating(this, this.triggerTarget, null, { useHide: false });

    this.floating.on('open', this.onOpen.bind(this));
    this.floating.on('close', this.onClose.bind(this));

    this.appendTo = null;
    this.shouldUpdateShiftUsage = true;
    this.debouncedUpdateUseShift = debounce(this.updateUseShift.bind(this), 250);
    this.debouncedUpdateHeaderHeight = debounce(this.updateHeaderHeight.bind(this), 250);
    this.mutationObserver = new MutationObserver(this.handleMutations.bind(this));
    this.originalUseShift = this.useShiftValue;
  }

  connect() {
    if (this.isTurboPreview) return;

    this.floating.set({
      offset: this.offsetValue,
      placement: this.placementValue,
      skipHeaderPadding: this.skipHeaderPaddingValue,
      useShift: this.useShiftValue,
      shiftOptions: {
        crossAxis: this.isNestedMenuValue,
      },
    });

    this.initObserve();
    this.initAppendTo();

    if (this.openOnLoadValue) {
      this.openOnLoadValue = false;
      this.element.open = true;
    }
  }

  disconnect() {
    this.destroy();
  }

  offsetValueChanged() {
    this.floating.set({ offset: this.offsetValue });
  }

  placementValueChanged() {
    this.floating.set({ placement: this.placementValue });
  }

  skipHeaderPaddingValueChanged() {
    this.floating.set({ skipHeaderPadding: this.skipHeaderPaddingValue });
  }

  useShiftValueChanged() {
    this.floating.set({ useShift: this.useShiftValue });
  }

  destroy() {
    this.mutationObserver.disconnect();

    if (this.appendTo) {
      this.bodyContainerTarget.appendChild(this.floating.content());
      this.appendTo = null;
    }

    this.useShiftValue = this.originalUseShift;

    this.floating.destroy();
    this.floating.set({ content: null });
  }

  initObserve() {
    this.mutationObserver.observe(this.element, {
      attributes: true,
      attributeOldValue: true,
    });
  }

  initAppendTo() {
    this.appendTo = this.element.closest('[data-dropdown-append-to]');
    this.floating.set({ content: this.bodyTarget });

    if (this.appendTo) {
      if (this.appendToValue === 'parent') {
        this.appendTo = this.appendTo.parentElement;
      }

      this.bodyTarget.remove();
    }
  }

  scheduleUpdateHeaderHeight() {
    this.debouncedUpdateHeaderHeight();
  }

  updateHeaderHeight() {
    this.floating.updateHeaderHeight();
  }

  scheduleUpdateUseShift() {
    if (!this.floating.opened()) {
      this.shouldUpdateShiftUsage = true;

      return;
    }

    this.debouncedUpdateUseShift();
  }

  updateUseShift() {
    const [mainAxis, crossAxis] = this.placementValue.split('-');

    if (!['top', 'bottom'].includes(mainAxis)) {
      this.useShiftValue = true;

      return;
    }

    if (!crossAxis) {
      this.useShiftValue = true;

      return;
    }

    if (!this.floating.content()) return;

    if (!this.floating.opened()) {
      Object.assign(this.floating.content().style, {
        visibility: 'hidden',
      });

      document.body.appendChild(this.floating.content());
    }

    const endRemaniningWidth = getOffset('left', this.triggerTarget, document.body) + this.triggerTarget.offsetWidth;
    const startRemaningWidth = getOffset('right', this.triggerTarget, document.body) + this.triggerTarget.offsetWidth;
    const maxRemainingWidth = Math.max(endRemaniningWidth, startRemaningWidth)
      - (this.detectOverflowOptions?.padding?.left || this.detectOverflowOptions?.padding || 0)
      - (this.detectOverflowOptions?.padding?.right || this.detectOverflowOptions?.padding || 0);

    this.useShiftValue = maxRemainingWidth < this.floating.content().offsetWidth || this.originalUseShift;

    if (!this.floating.opened()) {
      if (this.appendTo) {
        this.floating.content().remove();
      } else {
        this.bodyContainerTarget.appendChild(this.floating.content());
      }

      Object.assign(this.floating.content().style, {
        visibility: null,
      });
    }
  }

  handleMutations(mutations) {
    mutations.forEach((mutation) => {
      switch (mutation.type) {
        case 'attributes':
          if (mutation.attributeName === 'open') {
            let eventName = 'close';

            if (mutation.target[mutation.attributeName]) {
              if (this.shouldUpdateShiftUsage) {
                this.shouldUpdateShiftUsage = false;
                this.updateUseShift();
              }

              eventName = 'open';
              this.floating.open();
            } else {
              this.floating.close();
            }

            this.dispatch(eventName);
          }

          break;
        default:
      }
    });
  }

  onOpen() {
    if (this.appendTo) {
      this.appendTo.appendChild(this.floating.content());
    }

    if (this.overlayValue) {
      trapFocus(this.element);
    }
  }

  onClose() {
    this.element.open = false;

    if (this.overlayValue) {
      releaseFocus(this.element);
    }

    if (this.appendTo) {
      this.floating.content().remove();
    }
  }

  closeOnTriggerClick(event) {
    if (!this.floating.opened()) return;

    event.preventDefault();

    this.floating.close();
  }

  closeFromEvent({ target }) {
    if (!this.floating.opened()) return;
    if (this.element === target || this.element.contains(target)) return;
    if ((this.appendTo && this.floating.content() === target) || this.floating.content().contains(target)) return;
    if (target.dataset.choice || target.dataset.choice === '') return;

    this.floating.close();
  }

  close() {
    if (!this.floating.opened()) return;

    this.floating.close();
  }

  closeOnEscape(event) {
    if (!this.floating.opened()) return;

    event.preventDefault();
    event.stopPropagation();

    this.floating.close();
  }
}
